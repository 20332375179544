export default {
    residents: 'Medebewoners',
    residentsHelpText:
        'Verandert je situatie? Dan kan je je gegevens hier aanpassen. \n\n' +
        'Ga je bijvoorbeeld samenwonen? Of verlaat iemand het gezin?\n' +
        'Geef op met wie je gaat samenwonen of wie het gezin verlaat (ook je kinderen die deeltijds inwonen). ' +
        'Voeg hier eventuele medebewoners toe. Zo weten we voor wie je een huurwoning aanvraagt en hoeveel slaapkamers er nodig zijn.',
    addCoResidentsHelpText: 'Voeg hier alle medebewoners toe. Zo weten we voor wie je een huurwoning aanvraagt.',
    residentsIncompleteWarning:
        'Er ontbreken nog enkele gegevens. Gelieve de gegevens van volgende personen aan te vullen:',

    familyComposition: 'Gezinssamenstelling',
    minor: 'Persoon jonger dan 18 jaar',
    isResidentYoungerThan18: 'Is de medebewoner jonger dan 18 jaar?',
    sameAddressAsRequester: 'Woont op hetzelfde adres als de kandidaat huurder',
    addResident: 'Medebewoner toevoegen',
    addResidents: 'Medebewoners toevoegen',
    deleteResidentTitle: 'Medebewoner verwijderen',
    deleteResidentText: 'Ben je zeker dat je deze medebewoner wilt verwijderen?',

    hasChildSupport: 'Geniet dit kind van kinderbijslag of groeipakket?',
    hasChildSupportHelpText:
        'Het groeipakket bestaat uit gezinsbijslagen en andere financiële tegemoetkomingen om gezinnen te ondersteunen in de opvoeding van hun kinderen.',
    monthlyChildSupport: 'Hoeveel bedraagt je totale maandelijkse kinderbijslag of groeipakket?',

    expectingChildren: 'Zwangerschap',
    isExpecting: 'Ben jij of je medebewoner momenteel zwanger?',
    expectedAmount: 'Hoeveel kindjes verwacht je?',
    expectedDate: 'Wanneer is de uitgerekende datum?',
    pregnancyDocument: 'Laad een attest van zwangerschap op. Dat attest vraag je op bij je gynaecoloog.',

    now: 'Ja',
    none: 'Nee',
    in_the_next_six_months: 'Nee, maar wel in de komende 6 maanden',
    during_the_past_three_years: 'Nee, maar wel in de vorige 3 jaar',
    more_than_three_years_ago: 'Nee, maar wel langer dan 3 jaar geleden',

    FULL_TIME: 'Voltijds',
    MORE_THAN_5_NIGHTS_PER_2_WEEKS: 'Meer dan 5 nachten per 2 weken',
    LESS_THAN_5_NIGHTS_PER_2_WEEKS: 'Minder dan 5 nachten per 2 weken',
    NEVER: 'Nooit',
};
